<template>
  <v-card height="95vh" flat tile class="mb-n15 primarygrad">
    <v-row justify="center" align="center">
      <v-card width="800" class="ma-5 pa-5 mt-15">
        <v-img
          class="circle rounded mx-auto"
          src="../assets/logo.png"
          width="45"
          height="45"
        ></v-img>
        <!-- <v-card-title class="text-md-h6 justify-center"
          >Verify Your Email</v-card-title
        > -->
        <v-img class="mx-auto" width="200" src="../assets/verified.jpg"></v-img>
        <v-card-text class="text-center">
          <h5 class="text-md-h5 font-weight-bold">
            Your registration is complete now
          </h5>
        </v-card-text>
        <v-card-actions>
          <v-btn small class="primary mx-auto" @click="$router.replace('/home')"
            >Proceed to Homepage</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
    <v-dialog v-model="loaderDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import fb from "../js/firebase";
export default {
  computed: {
    ...mapGetters(["ENDPOINT"]),
  },
  data() {
    return {
      logo: require("../assets/logo.png"),
      loaderDialog: false,
    };
  },
  created() {
    this.verify();
  },
  methods: {
    async verify() {
      const self = this;
      const user = self.$router.currentRoute.query.user;
      const token = self.$router.currentRoute.query.token;
      // query
      if (user && token) {
        try {
          var data = {
            accountId: user,
            tokenId: token,
          };

          await this.$http
            .post(`${this.ENDPOINT}/accounts/verify-email`, { data: data })
            .then((response) => {
              try {
                fb.log("verify_email");
              } catch (e) {
                console.log("Error in FB log", e);
              }

              // this.$swal({
              //   type: "success",
              //   text: response.body.message,
              // });
              if (this.$store.getters.user) {
                var user = JSON.parse(JSON.stringify(this.$store.getters.user));
                user.isEmailVerified = true;
                this.$store.commit("setUser", user);
              }
              // this.$router.replace("/home");
            })
            .catch((e) => {
              //this.$swal({ type: "error", text: e.body.error.message });
              if (this.$store.getters.user) return this.$router.replace("/404");
              return this.$router.replace("/");
            });
        } catch (e) {
          console.log(e);
        }
      } else {
        if (this.$store.getters.user) return this.$router.replace("/404");
      }
    },
  },
};
</script>
<style scoped></style>
